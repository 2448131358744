<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin/news" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">
            <v-container>

              <v-row>
                <v-col class="mb-4 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="blue" size="38"><v-icon dark>mdi-newspaper</v-icon></v-avatar>
                    Nueva Noticia
                  </p>
                </v-col>
              </v-row>

            </v-container>
          </v-card-title>
          
          <v-card-text>
            <v-container>

              <!-- General info inputs -->
              <v-row>
                <v-col>
                  <v-text-field 
                    v-model="newArticle.title" 
                    label="Nombre de la noticia*" 
                    outlined dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="newArticle.dateDay"
                    type="date"
                    label="Fecha*"
                    outlined dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      v-model="newArticle.dateHour"
                      type="time"
                      label="Hora*"
                      outlined dense
                  />
                </v-col>
                <v-col cols="4">
                  <v-file-input
                    v-model="newArticle.articleImg"
                    accept="image/*"
                    label="Imagen de portada*"
                    outlined dense
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-5"></v-divider>

              <!-- Markdown Editor -->
              <v-row>
                <mavon-editor v-model="newArticle.markdown" language="en" />
              </v-row>

              <v-divider class="my-6"></v-divider>

              <!-- Submit -->
              <v-row>
                <v-col cols="6">
                  <v-checkbox
                    v-model="newArticle.isVisible"
                    label="¿Es visible para el público?"
                  />
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn @click="saveArticle()" color="success">
                    <v-icon class="mr-2">mdi-floppy</v-icon>
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </v-container>
</template>

<script>
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

export default {
  name: `AdminCreateArticle`,
  components: {
    mavonEditor
  },
  data: () => ({
    //state
    isSaving: false,
    isSnackbar: false,
    snackcolor: 'success',
    snacktext: '',
    //data
    headers: [
      { text: 'Nombre', value: 'name' },
      { text: 'Profesor', value: 'professorName' },
      { text: 'Email del Profesor', value: 'professorEmail' },
      { text: 'Cupos', value: 'slots' },
      { text: 'Acciones', value: 'actions' },
    ],
    //form
    newArticle: {
      title: null,
      description: null,
      articleImg: null,
      markdown: null,
      dateDay: null,
      dateHour: null,
      isVisible: true,
    }
  }),
  methods: {
    async saveArticle() {
      try {

        if(!this.newArticle.title || !this.newArticle.articleImg || !this.newArticle.dateDay || !this.newArticle.dateHour || !this.newArticle.markdown) return this.invalidFormNotification()
        
        this.isSaving = true

        let fd = new FormData()
        fd.append('title', this.newArticle.title)
        fd.append('date', `${this.newArticle.dateDay}T${this.newArticle.dateHour}`)
        fd.append('markdown', this.newArticle.markdown)
        fd.append('isVisible', this.newArticle.isVisible)
        fd.append('articleImg', this.newArticle.articleImg)

        await this.axios.post(`${process.env.VUE_APP_APIURL}/article`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.snackcolor = 'success'
        this.snacktext = 'Noticia creada'
        this.isSnackbar = true

        this.$router.push('/admin/news')

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al guardar la noticia'
        this.isSnackbar = true
      } finally {
        this.isSaving = false
      }
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete los campos requeridos'
      this.isSnackbar = true
    },
  },
}
</script>